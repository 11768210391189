<template>
  <div>
    <b-overlay
      :show="Over"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-card v-if="UserData.hide === 1">
          <h3>
            <i class="fad fa-volleyball-ball" /> เพิ่ม Agent SBOBET
          </h3>
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>SbobetของAgent</label>
                  <validation-provider
                    #default="{ errors }"
                    name="AgentId"
                    rules="required"
                  >
                    <b-form-select
                      v-model="AgentId"
                      :options="AgentList"
                      value-field="id"
                      text-field="username"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>ชื่อบัญชีผู้ใช้</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required|min:6"
                  >
                    <b-form-input
                      v-model="Data.Username"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกชื่อบัญชีผู้ใช้ให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>รหัสผ่าน</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required|min:4"
                  >
                    <b-form-input
                      v-model="Data.Password"
                      :state="errors.length > 0 ? false:null"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกรหัสผ่านให้ถูกต้อง' : '' }}</small>
                    <p class="small m-0">
                      รหัสต้องมีความยาวอย่างน้อย 4 ตัว แต่ไม่เกิน 8 ตัว สามารถมีตัวเลข (0-9) และตัวอักษรภาษาอังกฤษพิมพ์เล็กหรือพิมพ์ใหญ่
                    </p>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>แทงต่ำสุด</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Min"
                    rules="required"
                  >
                    <b-form-input
                      v-model="Data.Min"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกแทงต่ำสุด' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>แทงสูงสุด</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Max"
                    rules="required"
                  >
                    <b-form-input
                      v-model="Data.Max"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกแทงสูงสุด' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>แทงสูงสุดต่อแม็ต</label>
                  <validation-provider
                    #default="{ errors }"
                    name="MaxPerMatch"
                    rules="required"
                  >
                    <b-form-input
                      v-model="Data.MaxPerMatch"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกแทงสูงสุดต่อแม็ต' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Casino Table Limit</label>
                  <validation-provider
                    #default="{ errors }"
                    name="CasinoTableLimit"
                    rules="required"
                  >
                    <b-form-select
                      v-model="Data.CasinoTableLimit"
                      :options="optionsLimit"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Company Key</label>
                  <validation-provider
                    #default="{ errors }"
                    name="CompanyKey"
                    rules="required"
                  >
                    <b-form-input
                      v-model="Data.CompanyKey"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Server Id</label>
                  <validation-provider
                    #default="{ errors }"
                    name="ServerId"
                    rules="required"
                  >
                    <b-form-input
                      v-model="Data.ServerId"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>อัพเดทสถานะ</label> <br>
                  <b-button-group>
                    <b-button
                      variant="outline-primary"
                      :class="{ 'active': isActive }"
                      @click="toggleActive1"
                    >
                      ใช้งาน
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      :class="{ 'active2': isActive2 }"
                      @click="toggleActive2"
                    >
                      ระงับ
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      :class="{ 'active3': isActive3 }"
                      @click="toggleActive3"
                    >
                      ปิด
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="UserData.hide === 1"
                  variant="gradient-primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <i class="fas fa-download" /> บันทึกข้อมูล
                </b-button>
              </b-col>
              <!-- <b-col cols="12">
            <b-button
              variant="gradient-primary"
              type="submit"
              @click.prevent="validationForm"
            >
              <i class="fas fa-download" /> แก้ไขข้อมูล
            </b-button>
          </b-col> -->
            </b-row>
          </b-form>
        </b-card>
      </validation-observer>
      <validation-observer ref="simpleRules2">
        <b-card>
          <h3>
            <i class="fad fa-volleyball-ball" /> การตั้งค่า SBOBET
          </h3>
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>ชื่อบัญชีผู้ใช้</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required|min:6"
                  >
                    <b-form-input
                      v-model="DataUpdate.Username"
                      type="text"
                      disabled
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกชื่อบัญชีผู้ใช้ให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>แทงต่ำสุด</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Min"
                    rules="required"
                  >
                    <b-form-input
                      v-model="DataUpdate.Min"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกแทงต่ำสุด' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>แทงสูงสุด</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Max"
                    rules="required"
                  >
                    <b-form-input
                      v-model="DataUpdate.Max"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกแทงสูงสุด' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>แทงสูงสุดต่อแม็ต</label>
                  <validation-provider
                    #default="{ errors }"
                    name="MaxPerMatch"
                    rules="required"
                  >
                    <b-form-input
                      v-model="DataUpdate.MaxPerMatch"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกแทงสูงสุดต่อแม็ต' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Casino Table Limit</label>
                  <validation-provider
                    #default="{ errors }"
                    name="CasinoTableLimit"
                    rules="required"
                  >
                    <b-form-select
                      v-model="DataUpdate.CasinoTableLimit"
                      :options="optionsLimit"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Company Key</label>
                  <validation-provider
                    #default="{ errors }"
                    name="CompanyKey"
                    rules="required"
                  >
                    <b-form-input
                      v-model="DataUpdate.CompanyKey"
                      type="text"
                      disabled
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Server Id</label>
                  <validation-provider
                    #default="{ errors }"
                    name="ServerId"
                    rules="required"
                  >
                    <b-form-input
                      v-model="DataUpdate.ServerId"
                      type="text"
                      disabled
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกข้อมูลให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>อัพเดทสถานะ</label> <br>
                  <b-button-group>
                    <b-button
                      variant="outline-primary"
                      :class="{ 'active': UpdateisActive }"
                      @click="UpdateActive1"
                    >
                      ใช้งาน
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      :class="{ 'active2': UpdateisActive2 }"
                      @click="UpdateActive2"
                    >
                      ระงับ
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      :class="{ 'active3': UpdateisActive3 }"
                      @click="UpdateActive3"
                    >
                      ปิด
                    </b-button>
                  </b-button-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="gradient-warning"
                  type="submit"
                  @click.prevent="validationFormUpdate"
                >
                  <i class="fas fa-download" /> แก้ไขข้อมูล
                </b-button>
              </b-col>
              <!-- <b-col cols="12">
            <b-button
              variant="gradient-primary"
              type="submit"
              @click.prevent="validationForm"
            >
              <i class="fas fa-download" /> แก้ไขข้อมูล
            </b-button>
          </b-col> -->
            </b-row>
          </b-form>
        </b-card>
      </validation-observer>
      <b-row>
        <b-col
          v-for="items in SboList"
          :key="items.id"
          md="3"
          class="mb-1"
        >
          <app-collapse
            accordion
            type="Margin"
          >
            <app-collapse-item
              :title="`${items.text}`"
            >
              <b-form-group
                label="Market Type"
                label-for="basicInput"
              >
                <v-select
                  id="basicInput"
                  v-model="items.market_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="MarketList"
                  label="text"
                  :reduce="name => Number(name.value)"
                />
              </b-form-group>

              <b-form-group
                label="แทงต่ำสุด"
                label-for="basicInput2"
              >
                <b-form-input
                  id="basicInput2"
                  v-model="items.min_bet"
                  class="rounded"
                />
              </b-form-group>

              <b-form-group
                label="แทงสูงสุด"
                label-for="basicInput3"
              >
                <b-form-input
                  id="basicInput3"
                  v-model="items.max_bet"
                  class="rounded"
                />
              </b-form-group>

              <b-form-group
                label="แทงสูงสุดต่อแม็ต"
                label-for="basicInput4"
              >
                <b-form-input
                  id="basicInput4"
                  v-model="items.max_bet_per_match"
                  class="rounded"
                />
              </b-form-group>

              <b-button
                variant="success"
                @click="FormSboType(items)"
              >
                บันทึกข้อมูล
              </b-button>
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BButtonGroup,
  BOverlay, BIconController,
} from 'bootstrap-vue'
import { required, email, min } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BButtonGroup,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      Over: false,
      emailValue: '',
      name: '',
      required,
      email,
      min,
      isActive: false,
      isActive2: false,
      isActive3: false,
      UpdateisActive: false,
      UpdateisActive2: false,
      UpdateisActive3: false,
      Data: {
        Username: '',
        Password: '',
        Min: '',
        Max: '',
        MaxPerMatch: '',
        CasinoTableLimit: '',
        CompanyKey: '',
        ServerId: '',
        Status: 'Active',
      },
      DataUpdate: {
        Username: '',
        Min: '',
        Max: '',
        MaxPerMatch: '',
        CasinoTableLimit: '',
        CompanyKey: '',
        ServerId: '',
        Status: '',
      },
      optionsLimit:
            [
              { value: 1, text: 'ต่ำสุด' },
              { value: 2, text: 'ปานกลาง' },
              { value: 3, text: 'สูงสุด' },
              { value: 4, text: 'VIP (ทั้งหมด)' },
            ],
      AgentList: [],
      AgentId: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      MarketList: [],
      SboList: [],
    }
  },
  mounted() {
    this.toggleActive1()
    this.GetAgentList()
    this.GetAgent()
    this.GetListSbo()
    this.GetListMarket()
  },
  methods: {
    GetAgentList() {
      this.$http
        .get('/agent/sbo/list')
        .then(response => {
          this.AgentList = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetAgent() {
      this.Over = true
      this.$http
        .get('/zean/agent/show')
        .then(response => {
          this.Over = false
          const Mes = response.data
          this.DataUpdate = {
            Username: Mes.sbo_username,
            Min: Mes.sbo_min,
            Max: Mes.sbo_max,
            MaxPerMatch: Mes.sbo_maxpermatch,
            CasinoTableLimit: Mes.sbo_casinotablelimit,
            CompanyKey: Mes.sbo_companykey,
            ServerId: Mes.sbo_serverid,
          }
          console.log(Mes.sbo_status)
          if (Mes.sbo_status === 'Active') {
            this.UpdateActive1()
          } else if (Mes.sbo_status === 'Suspend') {
            this.UpdateActive2()
          } else if (Mes.sbo_status === 'Closed') {
            this.UpdateActive3()
          }
        })
        .catch(error => {
          this.Over = false
          console.log(error)
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.Over = true
          this.$http
            .post(`/sbobet/register/${this.AgentId}`, this.Data)
            .then(() => {
              this.Over = false
              this.Success('แก้ไขข้อมูลสำเร็จ')
            })
            .catch(() => {
              this.Over = false
              this.Error('แก้ไขข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    validationFormUpdate() {
      this.$refs.simpleRules2.validate().then(success => {
        if (success) {
          this.Over = true
          this.$http
            // .post(`/sbobet/register/${this.AgentId}`, this.Data)
            .post('/sbobet/update', this.DataUpdate)
            .then(() => {
              this.Over = false
              this.Success('แก้ไขข้อมูลสำเร็จ')
            })
            .catch(() => {
              this.Over = false
              this.Error('แก้ไขข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    FormSboType(Ele) {
      if (Ele) {
        console.log(Ele)
        this.Over = true
        this.$http
          .post('/sbobet/betsetting/update', Ele)
          .then(() => {
            this.Over = false
            this.Success('แก้ไขข้อมูลสำเร็จ')
          })
          .catch(() => {
            this.Over = false
            this.Error('แก้ไขข้อมูลไม่สำเร็จ')
          })
      }
    },
    GetListMarket() {
      this.$http
        .get('/sbobet/markettype/list')
        .then(response => {
          this.MarketList = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetListSbo() {
      this.$http
        .get('/sbobet/sbotype/list')
        .then(response => {
          this.SboList = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    toggleActive1() {
      this.Data.Status = 'Active'
      this.isActive = !this.isActive
      this.isActive2 = false
      this.isActive3 = false
    },
    toggleActive2() {
      this.Data.Status = 'Suspend'
      this.isActive2 = !this.isActive2
      this.isActive = false
      this.isActive3 = false
    },
    toggleActive3() {
      this.Data.Status = 'Closed'
      this.isActive3 = !this.isActive3
      this.isActive = false
      this.isActive2 = false
    },
    UpdateActive1() {
      this.DataUpdate.Status = 'Active'
      this.UpdateisActive = !this.UpdateisActive
      this.UpdateisActive2 = false
      this.UpdateisActive3 = false
    },
    UpdateActive2() {
      this.DataUpdate.Status = 'Suspend'
      this.UpdateisActive2 = !this.UpdateisActive2
      this.UpdateisActive = false
      this.UpdateisActive3 = false
    },
    UpdateActive3() {
      this.DataUpdate.Status = 'Closed'
      this.UpdateisActive3 = !this.UpdateisActive3
      this.UpdateisActive = false
      this.UpdateisActive2 = false
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Error(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.active {
  background-color: #077a0d !important;
  color: #ffffff !important;
  border: 1px solid #077a0d !important;
}
.active2 {
  background-color: #e20000 !important;
  color: #ffffff !important;
  border: 1px solid #e20000 !important;
}

.active3 {
  background-color: #858585 !important;
  color: #ffffff !important;
  border: 1px solid #858585 !important;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
